import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component:  () => import("@/views/Home.vue"),
    meta: {
      showNav: true
    }
  },
  {
    path: '/mySignature',
    name: 'SignatureGenerator',
    component: () => import('@/views/SignatureGenerator.vue'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/context/valophis',
    name: 'Valophis',
    alias: ['/valophis'],
    component: () => import('@/views/context/Valophis.vue'),
    meta: {
      showNav: false
    }
  },
  {
    path: '/aboutUs',
    name: 'Qui sommes-nous ?',
    component: () => import('@/views/AboutUs.vue'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/formations',
    name: 'Nos formations',
    component: () => import('@/views/Formations.vue'),
    meta: {
      showNav: true
    }
  },

  {
    path: '/digideck',
    alias: ['/dd'],
    name: 'Digideck',
    component: () => import('@/views/tools/Digideck.vue'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/valy',
    name: 'Valy',
    component: () => import('@/views/tools/Valy.vue'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/myFirstBusiness',
    alias: ['/mfb'],
    name: 'MyFirstBusiness',
    component: () => import('@/views/tools/MyFirstBusiness.vue'),
    meta: {
      showNav: true
    }
  },
  {
    path: '/smileAndSurvey',
    alias: ['/s&s'],
    name: 'Smile&Survey',
    component: () => import('@/views/tools/SmileAndSurvey.vue'),
    meta: {
      showNav: true
    }
  }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
