<template>
  <v-app>
    <Header v-if="$route.meta.showNav"/>

    <v-main :class="!$route.name || ['Home'].includes($route.name) ? 'pt-0' : ''">
      <router-view class="w-100 overflow-hidden" />
    </v-main>

    <footer class="pa-5 mt-12 text-center" style="background-color: rgba(48, 48, 128, .05);" v-if="$route.meta.showNav">
      COPYRIGHT &copy; {{ new Date().getFullYear() }} Lœsus Pedagogia, All rights Reserved
    </footer>
  </v-app>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  components: { Header }
}
</script>

<style lang="scss">
@media print {
  .pagebreak {
    page-break-before: always;
  }
  .hide-on-print {
    display: none;
  }
}


.redirect {
  --primary: #322b80;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease-in-out;
  &::before {
    content: '';
    background: var(--primary);
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.3s ease-in-out;
  }
  &.redirect-small::before {
    bottom: -1px;
    height: 1px;
  }
  &:hover::before {
    width: 100%;
  }
}
</style>